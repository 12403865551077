import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/general-care/aquaphor-baby-shampoo-front.webp";
import backImage from "images/baby/general-care/aquaphor-baby-shampoo-back.webp";
import frontZoomImage from "images/baby/general-care/aquaphor-baby-shampoo-front-zoom.webp";
import backZoomImage from "images/baby/general-care/aquaphor-baby-shampoo-back-zoom.webp";

import img2 from "images/baby/general-care/advanced-repair-callout-footer.webp";
import img3 from "images/baby/general-care/aquaphor-ointment-callout-footer.webp";

import "./aquaphorbabywashshampoo.scss";

function AquaphorBabyWashShampooPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-generalcare"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Baby Wash & Shampoo"
        pageDescription="Learn about Aquaphor Baby Wash & Shampoo"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#5daee1"
              categoryName="GENERAL CARE"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Baby Wash & Shampoo"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold pictonblue-text sub-title">
                  2-in-1 solution that gently cleanses without drying
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Baby Wash &amp; Shampoo
                  </span>{" "}
                  is specially designed for babies’ sensitive skin. It cleanses
                  with a light lather that rinses easily and washes hair without
                  irritating the scalp.
                </p>
                <ul className="picton-bullets">
                  <li>
                    <span>Extra mild, tear-free formula</span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Gentle on babies' sensitive skin, hair, and scalp
                    </span>
                  </li>
                  <li>
                    <span>
                      Enriched with soothing chamomile essence and provitamin B5
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Key ingredients
                </p>
                <p>
                  Water, decyl glucoside, sodium myreth sulfate, PEG-200
                  hydrogenated glyceryl palmate, cocamidopropyl betaine,
                  panthenol, bisabolol, polyquaternium-10, sea salt, citric
                  acid, sodium chloride, sodium benzoate
                </p>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold pictonblue-text sub-title">
                    Formulated for babies' sensitive skin
                  </p>
                  <ul className="checkmarks pictonblue">
                    <li>
                      <span>HYPOALLERGENIC</span>
                    </li>
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                    <li>
                      <span>PARABEN-FREE</span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img2}
                  imgAlt="EUCERIN® ADVANCED REPAIR CREAM"
                  caption="For immediate and <nobr>long-lasting<nobr> <br class='show-desktop' /> moisture"
                  buttonText="EUCERIN<sup>®</sup> ADVANCED REPAIR CREAM"
                  buttonClass="long"
                  buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img3}
                  imgAlt="USES OF AQUAPHOR HEALING OINTMENT"
                  caption="See the many uses of Aquaphor<br class='show-desktop' /> Healing Ointment<sup>&reg;</sup> for babies"
                  buttonText="AQUAPHOR HEALING OINTMENT"
                  buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AquaphorBabyWashShampooPage;
